import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-regular-svg-icons'

const History = ({location}) => {

  const Hr = () => <hr className="dots-hr mt-3 mb-4" />
  const Circle = () => <FontAwesomeIcon icon={faCircle}/>

  return(
    <Layout location={location} title="沿⾰" >
      <SEO title="沿⾰" description="創業から現在にいたるまでの沿⾰についてご覧いただけます。"/>

      <div className="page-head">
				<Container expand="lg">
					<h2>沿⾰</h2>
					<p>創業から現在にいたるまでの沿⾰についてご覧いただけます。</p>
				</Container>
				<div className="bottom-border" />
			</div>

      <Container expand="lg">
        <Row>
          <Col lg={2}><p><Circle /> <strong>1997/11</strong></p></Col>
          <Col lg={10}><p>愛知県刈⾕市に設⽴</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2000/05</strong></p></Col>
          <Col lg={10}><p>東京事業所開設</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2001/03</strong></p></Col>
          <Col lg={10}><p>中部地区中堅プロバイダー 株式会社インフォウェア買収</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2008/01</strong></p></Col>
          <Col lg={10}><p>株式会社フロンティア・ドメイン（本社︓東京都新宿区）を事業譲渡により経営統合</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2009/08</strong></p></Col>
          <Col lg={10}><p>G Data Software ⽇本代理店として販売開始</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2010/04</strong></p></Col>
          <Col lg={10}><p>経済産業省「業界横断共通EDI開発プロジェクト」により完成した、クラウドEDI「EcoChange」サービス開始</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2011/05</strong></p></Col>
          <Col lg={10}><p>情報機器販売・輸出⼊ 株式会社コンパス買収</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2012/04</strong></p></Col>
          <Col lg={10}><p>本社を名古屋市中村区（名駅）へ移転</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2013/10</strong></p></Col>
          <Col lg={10}><p>ISO27001 情報マネジメントシステム 認証取得</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2014/04</strong></p></Col>
          <Col lg={10}><p>国際標準CEFACT対応XML-EDI「EcoChange」サービス開始</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2015/04</strong></p></Col>
          <Col lg={10}><p>東京事業所を東京都千代⽥区（秋葉原）へ移転</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2015/06</strong></p></Col>
          <Col lg={10}><p>OBC 奉⾏ for クラウド販売パートナー 認定</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2015/10 </strong></p></Col>
          <Col lg={10}><p>APN（AWS Partner Network）スタンダードコンサルティングパートナー 認定</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2016/03</strong></p></Col>
          <Col lg={10}><p>PepperパートナープログラムによりPepperアプリ開発パートナーとして、ロボアプリパートナー（BASIC）認定</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2016/04</strong></p></Col>
          <Col lg={10}>
            <p>
              消費税軽減税率制度対応 B型受発注システム改修等⽀援指定事業者 認定<br/>
              history list iconUN/CEFACT準拠 クラウド型XML EDI EcoChange 消費税軽減税率対策補助⾦ 対応商品、対応サービス 認定
            </p>
          </Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2017/10</strong></p></Col>
          <Col lg={10}><p>⻘森事業所開設</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2018/03</strong></p></Col>
          <Col lg={10}><p>情報機器販売・輸出⼊ 株式会社コンパス合併</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2019/07</strong></p></Col>
          <Col lg={10}><p>株式会社ラクス 販売パートナー認定</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>2022/12</strong></p></Col>
          <Col lg={10}><p>ダイコク電機株式会社と株式譲渡契約締結</p></Col>
          <Col lg={12}><Hr /></Col>

        </Row>
      </Container>
    </Layout>
  )
}


export default History